<template>
  <div>
    <button @click="genPush">Push 1</button>
  </div>
</template>

<script>

import {notificationList, NOTIFY_TYPES} from "@store/test/testNotification";

export default {
  data() {
    return {
    };
  },
  methods: {
    genPush() {
      const test = {
        id: 1005,
        user: {
          firstName: 'John',
          id: 'a9a1cce3-8170-4370-abeb-8b077a9ae3e5',
          lastName: 'Rex',
          mail: 'rexty@dev.chatmail.rtelekom.sparklingtide.dev',
          profilePhoto: null
        },
        action: 'write a message:',
        type: NOTIFY_TYPES.COMMUNITY_CHAT_MESSAGE,
        title: 'I will check this out.',
        community: [
          { id: 1,  name: 'Frontend Team' }
        ],
        datetime: '2022-04-21T16:43:20',
        isRead: false
      };
      
      DIALOG.push(test);
      notificationList.value.push(test);
      
    }
  }
};

</script>

<style lang="scss" scoped>

.page-test {
  background-color: #fff;
}


</style>
